@import 'variables';

.theme-primary-color {
  color: $primary-color;
}

.d-flex {
  display: flex !important;
}

.d-block-sm {
  @media only screen and (max-width: 576px) {
    display: block !important;
  }
}

.d-flex-center-space-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.d-flex-center-start {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.d-flex-center-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.d-flex-space-between {
  display: flex;
  justify-content: space-between;
}

.d-flex-start-space-between {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.flex-column {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

/*--------------------------------------------------------*/

.w-100 {
  width: 100% !important;
}

.w-75 {
  width: 75% !important;
}

.w-50 {
  width: 50% !important;
}
.w-25 {
  width: 25% !important;
}

.w-auto {
  width: auto !important;
}

.w-fit-content {
  width: fit-content !important;
}
.w-min-content {
  width: min-content !important;
}
.w-max-content {
  width: max-content !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-inherit {
  height: inherit !important;
}

.w-sm-100 {
  @media only screen and (max-width: 576px) {
    width: 100% !important;
  }
}
/*--------------------------------------------------------*/

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pr-1 {
  padding-right: 0.25rem !important;
}

.pr-2 {
  padding-right: 0.5rem !important;
}

.pr-3 {
  padding-right: 1rem !important;
}

.pr-4 {
  padding-right: 1.5rem !important;
}

.pr-5 {
  padding-right: 3rem !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.pl-1 {
  padding-left: 0.25rem !important;
}

.pl-2 {
  padding-left: 0.5rem !important;
}

.pl-3 {
  padding-left: 1rem !important;
}

.pl-4 {
  padding-left: 1.5rem !important;
}

.pl-5 {
  padding-left: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}
.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}
.mr-0 {
  margin-right: 0 !important;
}

.mr-1 {
  margin-right: 0.25rem !important;
}

.mr-2 {
  margin-right: 0.5rem !important;
}

.mr-3 {
  margin-right: 1rem !important;
}

.mr-4 {
  margin-right: 1.5rem !important;
}

.mr-5 {
  margin-right: 3rem !important;
}
.ml-1 {
  margin-left: 0.25rem !important;
}

@media only screen and (max-width: 992px) {
  .w-lg-100 {
    width: 100% !important;
  }
  .flex-flow-lg-column {
    flex-flow: column;
  }
  .flex-wrap-lg-wrap {
    flex-wrap: wrap;
  }
  .d-lg-flex-start {
    display: flex;
    align-items: flex-start;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .m-lg-0 {
    width: 0 !important;
  }
  .ml-lg-0 {
    margin-left: 0 !important;
  }
  .mt-lg-5 {
    margin-top: 3rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }
}

.font-500 {
  font-weight: 500 !important;
}

/**-----------------**/
.d-none {
  display: none !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-bold {
  font-weight: 600;
}

.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.position-relative {
  position: relative;
}

.w-webkit-fill-available {
  width: -webkit-fill-available;
}

.cursor-pointer {
  cursor: pointer;
}

.button-default-css-override {
  border: none;
  background-color: transparent;
}

.border-light-grey {
  border: 1px solid #e2e2e2;
}

// accordian header
.accordian-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  .title {
    color: #000;
    font-size: 14px;
    font-weight: 500;
  }
  .header-options {
    display: flex;
    align-items: center;
    .title-options-inner {
      margin-left: 60px;
      text-align: right;
      color: #41a4a6;
      font-weight: 600;
      cursor: pointer;
      @media only screen and (max-width: 992px) {
        margin-left: 20px;
      }
    }
  }
  .anticon-caret-down {
    position: absolute;
    right: -25px;
  }
  .anticon-info-circle {
    color: #27a6a4;
  }
}
.ant-collapse-header {
  background-color: #d9eded;
}

button {
  cursor: pointer;
}

//footer
.footer-section {
  height: 50px;
}

.footer-section-wrapper {
  position: fixed;
  bottom: 0;
  width: calc(100% - 85px);
  background-color: #fff;
  padding: 15px 20px;
  left: 85px;
  box-shadow: 0px 0px 6px -2px #000;
  z-index: 5;
  text-align: right;
}

.disable-section {
  opacity: 0.7;
  pointer-events: none;
  .ant-select-arrow .anticon:not(.ant-select-suffix) {
    pointer-events: none !important;
  }
}

.enable-section {
  opacity: 1;
  pointer-events: auto;
}

//export
.export-section {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: #27a6a4;
  margin-bottom: 0.5rem;

  .export-icon {
    margin-right: 5px;
    font-size: 18px;
    cursor: pointer;
  }

  .export-text {
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
  }
}

//spinner style
.spinner-style {
  position: fixed;
  width: calc(100% - 85px);
  height: 100vh;
  top: 0;
  left: 85px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffffb8;
}

//ant theme to default color
.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #27a6a4;
}
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  border-color: #27a6a4;
}

.ant-select .ant-select-arrow .anticon.anticon-loading {
  color: #27a6a4;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #27a6a438;
}

.ant-select-item-option-selected .ant-select-selected-icon {
  color: #27a6a4;
}

.ant-select-multiple .ant-select-selection-item-content {
  max-width: 20ch;
}

.custom-default-btn {
  &:hover,
  &:active,
  &:focus {
    border-color: #d9d9d9 !important;
    color: #000 !important;
  }
}
