/* You can add global styles to this file, and also import other style files */

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

@import "assets/styles/common";
@import "assets/styles/variables";

body {
  font-family: "Open Sans", sans-serif;
  margin: 0;
  color: $primary-font-color;
}

/*---Scrollbar Styles---*/

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: #fff;
}

::-webkit-scrollbar-thumb {
  background: #b2b0b6;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #888;
}

.cdk-global-scrollblock {
  overflow-y: inherit !important;
}

.common-style {
  border-radius: 2px;
  padding: 10px;
  border: 1px solid #ffffff;
}

input:focus {
  outline: none !important;
}
textarea:focus {
  outline: none !important;
}

//custom search textbox
/* The container */
.checkbox-option-container {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  user-select: none;
  font-weight: 500;
}

/* Hide the browser's default checkbox */
.checkbox-option-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 50%;
  left: 0;
  height: 20px;
  width: 20px;
  transform: translateY(-50%);
  border: 1px solid #cfcfcf;
  border-radius: 100%;
}

/* On mouse-over, add a grey background color */
.checkbox-option-container:hover input ~ .checkmark {
  box-shadow: 0px 0px 7px -3px #000;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-option-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-option-container .checkmark:after {
  width: 10px;
  height: 10px;
  background-color: #41a4a6;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 100%;
}

.ant-notification {
  left: 50% !important;
  transform: translateX(-50%) !important;
  right: auto !important;
  .ant-notification-notice {
    width: 100%;
    padding-right: 40px;
    &.notification-error {
      border: 1px solid #ffdfdc;
      background-color: #fff2f0;
    }
    &.notification-success {
      border: 1px solid #b8eb90;
      background-color: #f6ffed;
    }
    .ant-notification-notice-close {
      right: 10px;
      top: 8px;
    }
  }
}
