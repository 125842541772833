/* colors */

$primary-color: #27A6A4;
$dark-primary-color: #0a7e92;
$secondary-color: #FF9900;
$primary-font-color: #454250;
$secondary-font-color: #1A1628;
$active-font-color: #2d8b93;


$success-border-color: #00AA67;
$success-bg-color: #F3FCF4;
$success-color: #00AA67;
$tab-text-color: #27A6A4;

$warning-bg-color: #FFFDEF;
$warning-border-color: #FFDF7E;
$warning-color: #F3BA2F;

$error-color: #E65428;
$error-border-color: #FFB6A5;
$error-bg-color: #FFEDE9;

$white: #ffffff;
$black: #000000;
$primary-border-color: #dedcde;
$primary-box-shadow-color: #1A16281E;
$disabled-color: #0000003F;


/* values */

$default-font-size: 16px;
$default-ipad-font-size: 16px;
$default-desktop-font-size: 14px;


/* FONT SIZE */
$font12: 12px;
$font14: 14px;
$font16: 16px;
$font18: 18px;
$font32: 32px;

